import React, { useEffect } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'
import useUserService from '../api/useUserService'
import MfaView from './authentication/MfaView'
import { numberOfMinutesSinceLogin } from '../utils/utils'

function PrivateRoute({ component: Component, children, path, location, ...rest }) {
  const routerDomLocation = useLocation()
  const history = useHistory()
  const { user, logout, isAuthenticated, isLoading, isUserVerifiedOrTrusted, isWithMFAOrTrusted } =
    useUserService()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      history.push(`/sign-in?redirectTo=${routerDomLocation.pathname}${routerDomLocation.search}`)
    } else if (isAuthenticated && !isUserVerifiedOrTrusted()) {
      history.push(`/sign-up?redirectTo=${routerDomLocation.pathname}${routerDomLocation.search}`)
    }
  }, [isAuthenticated, isLoading])

  const render = (props) => {
    if (isAuthenticated === true) {
      if (!isWithMFAOrTrusted() && location.pathname !== '/configure-mfa') {
        return <MfaView />
      } else if (
        numberOfMinutesSinceLogin(user) > process.env.REACT_APP_MAXIMUM_LOGIN_TIME_IN_MINUTES
      ) {
        logout()
      } else {
        return <Component {...props} />
      }
    }
    return null
  }
  if (Component) {
    return <Route path={path} render={render} {...rest} />
  } else {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    )
  }
}

export default PrivateRoute
