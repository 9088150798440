import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCMSB_MOP0qy1G01tInsmghgHGbZXZios4',
  appId: '1:171754513280:web:78b2a532efc267ccf2d765',
  authDomain: 'app.gordium.eu',
  projectId: 'prd-smarthazop-app'
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
}

export default app
