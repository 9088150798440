import React, { useEffect, useState } from 'react'
import { multiFactor } from 'firebase/auth'
import { useHistory } from 'react-router-dom'
import BackgroundBalls from '../../components/BackgroundBalls'
import { UserAuth } from '../../contexts/AuthContext'
import Icon from '../../components/Icon'
import Input from '../../components/Input'
import Steps from '../../components/Steps'
import useQuery from '../../utils/useQuery'

export default function MfaView({ setForceShowLoginRefresh }) {
  const history = useHistory()
  const query = useQuery()
  const { isLoading, startEnrollMultiFactor, finishEnrollMultiFactor, unEnrollMultiFactor, user } =
    UserAuth()
  const [error, setError] = useState('')
  const [verificationId, setVerificationId] = useState(null)
  const [verificationCode, setVerificationCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [enrolledFactors, setEnrolledFactors] = useState()

  function refreshEnrolledFactors() {
    setEnrolledFactors(multiFactor(user).enrolledFactors)
  }

  useEffect(() => {
    if (user) {
      refreshEnrolledFactors()
    }
  }, [user])

  const handleStartEnrollMultiFactor = async () => {
    setError('')
    try {
      const receivedVerificationId = await startEnrollMultiFactor(phoneNumber)
      setVerificationId(receivedVerificationId)
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-phone-number':
          setError(
            `Error with phone number formatting. Please use a format including country code like +16505550101`
          )
          break
        case 'auth/second-factor-already-in-use':
          setError('This phone number is already in use')
          break
        case 'auth/requires-recent-login':
          setError('You are logged in for too long. Please login again before continuing.')
          setForceShowLoginRefresh(true)
          break
        default:
          setError(`Error enrolling second factor. ${e}`)
          break
      }
    }
  }

  const handleFinishEnrollMultiFactor = async () => {
    try {
      await finishEnrollMultiFactor(verificationId, verificationCode)
      history.push({
        pathname: '/person/me',
        search: query.toString()
      })
    } catch (e) {
      switch (e.code) {
        default:
          setError(`Error finishing second factor enrollment. ${e}`)
          break
      }
    }
  }

  const handleUnEnrollMultiFactor = async (uid) => {
    try {
      setError('')
      await unEnrollMultiFactor(uid)
      refreshEnrolledFactors()
    } catch (e) {
      switch (e.code) {
        case 'auth/multi-factor-info-not-found':
          setError('The MFA you are trying to remove is not longer active')
          break
        case 'auth/requires-recent-login':
          setError('You are logged in for too long. Please login again before continuing.')
          setForceShowLoginRefresh(true)
          break
        default:
          setError(e.message)
          break
      }
    }
  }

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleStartEnrollMultiFactor()
    }
  }

  const handleOnKeyUpVerificationCode = (event) => {
    if (event.key === 'Enter') {
      handleFinishEnrollMultiFactor()
    }
  }

  return (
    <>
      <BackgroundBalls />
      {!isLoading && (
        <div>
          <div className="columns is-flex is-vcentered is-fullheight">
            <div className="column">&nbsp;</div>
            <div className="column is-5-desktop is-11-mobile is-8-tablet">
              <div className="card">
                <div className="card-content p-5">
                  <p className="title has-text-centered is-4">Sign-up</p>
                  <Steps activeStep={3} />
                  <div className="content">
                    {user && (
                      <>
                        {error && <p className="has-text-warning">{error}</p>}
                        <div className="content">
                          <p>
                            Multi-factor authentication (MFA) is a user verification method that
                            requires more than one type of user validation. It prevents bad actors
                            from accessing an account even if they&lsquo;ve acquired the username
                            and password.
                          </p>
                          <p>
                            Enter your phone number below. An SMS will be sent to that number with a
                            6-digit code to enter in the next screen.
                          </p>
                        </div>
                        {enrolledFactors?.length > 0 && (
                          <div className="content">
                            <p>The MFA methods configured for your account are:</p>
                            <ul>
                              {enrolledFactors.map((factor) => (
                                <li key={factor.uid}>
                                  <span className="icon-text">
                                    {factor.phoneNumber && <span>SMS: {factor.phoneNumber}</span>}
                                    {!factor.phoneNumber && <span>{factor.displayName}</span>}
                                    <a
                                      className="has-text-danger "
                                      onClick={() => handleUnEnrollMultiFactor(factor.uid)}
                                    >
                                      <Icon icon="ic:outline-close" extraOuterClasses="ml-1" />
                                    </a>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {!verificationId && (
                          <div className="field">
                            <label className="label">Phone number</label>
                            <div className="control">
                              <Input
                                className="input"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onKeyUp={handleOnKeyUp}
                              />
                            </div>
                            <p className="help">
                              Use a phone number including country code +16505550101
                            </p>
                          </div>
                        )}
                        {verificationId && (
                          <>
                            <p>We&apos;ve sent a text message to {phoneNumber}</p>
                            <div className="field">
                              <label className="label">Verification code</label>
                              <div className="control">
                                <Input
                                  className="input"
                                  placeholder="Enter the 6-digit code"
                                  value={verificationCode}
                                  onChange={(e) => setVerificationCode(e.target.value)}
                                  onKeyUp={handleOnKeyUpVerificationCode}
                                />
                              </div>
                              <p className="help">
                                This site is protected by reCAPTCHA and the Google{' '}
                                <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                                <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
                                apply.
                              </p>
                            </div>
                          </>
                        )}
                        <div className="buttons is-right mt-4">
                          <button
                            type="button"
                            className="button is-ghost"
                            onClick={() => history.push('/person/me')}
                          >
                            <Icon
                              icon="ic:baseline-arrow-back"
                              extraOuterClasses="has-text-primary-dark-blue"
                            />
                            <span>Back</span>
                          </button>
                          {!verificationId && (
                            <button
                              type="button"
                              className="button is-rounded is-primary"
                              onClick={handleStartEnrollMultiFactor}
                            >
                              <span>Start MFA enrollment</span>
                            </button>
                          )}
                          {verificationId && (
                            <button
                              type="button"
                              className="button is-rounded is-primary"
                              onClick={handleFinishEnrollMultiFactor}
                            >
                              <span>Finish MFA enrollment</span>
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">&nbsp;</div>
          </div>
        </div>
      )}
    </>
  )
}
