import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoginWithMicrosoftButton from '../../components/LoginWithMicrosoftButton'
import Input from '../../components/Input'
import InputPassword from '../../components/InputPassword'
import { UserAuth } from '../../contexts/AuthContext'
import useQuery from '../../utils/useQuery'
import Icon from '../../components/Icon'
import { useFocus } from '../../utils/utils'

export default function SignInComponent({ refreshLogin = false }) {
  const history = useHistory()
  const query = useQuery()
  const {
    user,
    signIn,
    reauthenticateWithPassword,
    getMfaResolver,
    startMfaSignIn,
    finishMfaSignIn
  } = UserAuth()
  const [error, setError] = useState('')
  const [inputRef, setInputFocus] = useFocus()
  const [verificationCodeRef, setInputFocusVerificationCodeRef] = useFocus()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationId, setVerificationId] = useState()
  const [verificationCode, setVerificationCode] = useState('')
  const [mfaResolver, setMfaResolver] = useState()

  useEffect(() => {
    if (user) {
      if (query.has('redirectTo')) {
        history.replace(`${process.env.PUBLIC_URL}${query.get('redirectTo')}`)
      } else {
        history.replace(`${process.env.PUBLIC_URL}`)
      }
    }
  }, [user])

  useEffect(() => {
    if (verificationId) {
      setInputFocusVerificationCodeRef()
    } else {
      setInputFocus()
    }
  }, [verificationId])

  async function handleStartMfaSignIn(e) {
    const receivedMfaResolver = getMfaResolver(e)
    setMfaResolver(receivedMfaResolver)
    setVerificationId(
      await startMfaSignIn(receivedMfaResolver.hints[0], receivedMfaResolver.session)
    )
  }

  const handleSignIn = async () => {
    setError('')
    try {
      if (refreshLogin) {
        await reauthenticateWithPassword(password)
      } else {
        await signIn(email, password)
      }
      setVerificationCode('')
      setVerificationId(null)
      setMfaResolver(null)
      history.replace(`${process.env.PUBLIC_URL}${query.get('redirectTo') || ''}`)
    } catch (e) {
      switch (e.code) {
        case 'auth/wrong-password':
          setError('invalid username/password')
          break
        case 'auth/user-not-found':
          setError('invalid username/password')
          break
        case 'auth/user-disabled':
          setError('invalid username/password')
          break
        case 'auth/too-many-requests':
          setError('invalid username/password')
          break
        case 'auth/invalid-email':
          setError('Email address is not valid')
          break
        case 'auth/multi-factor-auth-required':
          handleStartMfaSignIn(e)
          break
        default:
          setError(e.message)
          break
      }
    }
  }

  const handleFinishMfaSignIn = async () => {
    try {
      await finishMfaSignIn(verificationId, mfaResolver, verificationCode)
      if (query.has('redirectTo')) {
        history.replace(`${process.env.PUBLIC_URL}${query.get('redirectTo')}`)
      }
    } catch (e) {
      switch (e.code) {
        case 'auth/missing-code':
          setError('Please fill in the verification code sent to your phone')
          break
        case 'auth/invalid-verification-code':
          setError('The verification code is incorrect, please check the code sent to your phone.')
          break
        default:
          setError(e.message)
          break
      }
    }
  }

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSignIn()
    }
  }

  const handleOnKeyUpVerificationCode = (event) => {
    if (event.key === 'Enter') {
      handleFinishMfaSignIn()
    }
  }

  return (
    <>
      {error && <p className="has-text-warning">{error}</p>}
      {!verificationId && (
        <>
          {!refreshLogin && (
            <>
              <p className="title has-text-centered is-4">Sign-in</p>
              <p className="mt-4 has-text-centered">Is your company using Microsoft?</p>
              <p className="mt-4 has-text-centered">
                <LoginWithMicrosoftButton />
              </p>
              <div className="columns is-vcentered pl-3 pr-3">
                <div className="column">
                  <hr />
                </div>
                <div className="column is-2 has-text-centered">or</div>
                <div className="column">
                  <hr />
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <Input
                    reference={inputRef}
                    name="email"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <InputPassword
                className="input"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={handleOnKeyUp}
              />
            </div>
            <p className="help">
              <a onClick={() => history.push('/forgot-password')}>Forgot password?</a>
            </p>
          </div>
        </>
      )}
      {verificationId && (
        <>
          <p>We&apos;ve sent a text message to {mfaResolver?.hints[0]?.phoneNumber}</p>
          <div className="field">
            <label className="label">Verification code</label>
            <div className="control">
              <Input
                reference={verificationCodeRef}
                className="input"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                onKeyUp={handleOnKeyUpVerificationCode}
              />
            </div>
            <p className="help">
              This site is protected by reCAPTCHA and the Google{' '}
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
          </div>
        </>
      )}
      <div className="buttons is-right mt-4">
        {!verificationId && (
          <>
            {!refreshLogin && (
              <button
                type="button"
                className="button is-ghost"
                onClick={() => history.push('/sign-up')}
              >
                <span>Sign up</span>
              </button>
            )}
            <button type="button" className="button is-rounded is-primary" onClick={handleSignIn}>
              <Icon icon="ic:outline-account-circle" />
              <span>{refreshLogin ? 'Re-authenticate' : 'Sign in'}</span>
            </button>
          </>
        )}
        {verificationId && (
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={handleFinishMfaSignIn}
          >
            <span>Verify code</span>
          </button>
        )}
      </div>
    </>
  )
}
