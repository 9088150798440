import React from 'react'

export default function Steps({ activeStep }) {
  return (
    <ul className="steps has-content-centered">
      <li className={`steps-segment ${activeStep === 1 ? 'is-active' : ''}`}>
        <span className="steps-marker">1</span>
        <div className="steps-content">
          <p className="is-size-6">Sign-up</p>
        </div>
      </li>
      <li className={`steps-segment ${activeStep === 2 ? 'is-active' : ''}`}>
        <span className="steps-marker">2</span>
        <div className="steps-content">
          <p className="is-size-6">Verify email address</p>
        </div>
      </li>
      <li className={`steps-segment ${activeStep === 3 ? 'is-active' : ''}`}>
        <span className="steps-marker">3</span>
        <div className="steps-content">
          <p className="is-size-6">Configure MFA</p>
        </div>
      </li>
    </ul>
  )
}
