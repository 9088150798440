import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getMultiFactorResolver,
  getRedirectResult,
  multiFactor,
  OAuthProvider,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  reauthenticateWithRedirect,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut
} from 'firebase/auth'
import { auth } from '../firebase'
import useCustomerStore from '../stores/customerStore'

const UserContext = createContext()

export function AuthContextProvider({ children }) {
  const provider = new OAuthProvider('microsoft.com')
  const [counter, setCounter] = useState(0)
  const [user, setUser] = useState(null)
  const [errors, setErrors] = useState([])
  const resourceRoles = useRef({})
  const [person, setPerson] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [issueNewAccessToken, setIssueNewAccessToken] = useState(false)
  const clearCustomerStore = useCustomerStore((state) => state.clearCustomerStore)

  provider.setCustomParameters({
    // Force re-consent.
    // prompt: 'consent',
    prompt: 'select_account'
    // Target specific email with login hint.
    // login_hint: 'user@firstadd.onmicrosoft.com'
  })

  const sendVerificationEmail = (verificationRedirectUrl) => {
    const actionCodeSettings = {
      url: verificationRedirectUrl
    }
    return sendEmailVerification(auth.currentUser, actionCodeSettings)
  }

  const createUser = async (email, password, verificationRedirectUrl) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    await sendVerificationEmail(verificationRedirectUrl)
    return userCredential.user
  }

  const signIn = async (username, password) => {
    setIsLoading(true)
    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password)
      setIsLoading(false)
      setIssueNewAccessToken(true)
      return userCredential
    } catch (e) {
      setIsLoading(false)
      throw e
    }
  }

  const reauthenticateWithPassword = (password) => {
    const credential = EmailAuthProvider.credential(user.email, password)
    setIssueNewAccessToken(true)
    return reauthenticateWithCredential(user, credential)
  }

  const handleActionCode = async (code) => {
    await applyActionCode(auth, code)
  }

  const passwordReset = (email) => {
    const actionCodeSettings = {
      url: `${window.location.origin}/password-reset`
    }
    return sendPasswordResetEmail(auth, email, actionCodeSettings)
  }

  const confirmThePasswordReset = (oobCode, newPassword) => {
    return confirmPasswordReset(auth, oobCode, newPassword)
  }

  const signInWithMicrosoft = async () => {
    await signInWithRedirect(auth, provider)
  }

  const reauthenticateWithMicrosoft = async () => {
    await reauthenticateWithRedirect(auth.currentUser, provider)
  }

  const logout = async () => {
    // explicitly wait on response, to make sure person is set to null after signOut is complete
    setIsAuthenticated(false)
    const ignore = await signOut(auth)
    setPerson(null)
    resourceRoles.current = {}
    return ignore
  }

  const getAccessToken = async () => {
    const accessToken = await auth.currentUser?.getIdToken(issueNewAccessToken)
    setIssueNewAccessToken(false)
    return accessToken
  }

  const fetchPerson = async () => {
    const token = await getAccessToken()
    fetch(`${process.env.REACT_APP_API_URI}v1/person/me`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setPerson(json)
            resourceRoles.current = {}
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const createRecaptchaVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' })
  }

  const startEnrollMultiFactor = async (phoneNumber) => {
    createRecaptchaVerifier()
    return multiFactor(auth.currentUser)
      .getSession()
      .then((multiFactorSession) => {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber,
          session: multiFactorSession
        }

        const phoneAuthProvider = new PhoneAuthProvider(auth)

        // Send SMS verification code.
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
      })
  }

  const finishEnrollMultiFactor = async (verificationId, verificationCode) => {
    if (verificationId) {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

      // Complete enrollment.
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'My cellphone number')
      setIssueNewAccessToken(true)
      await fetchPerson()
      setIsAuthenticated(true)
    }
  }

  const unEnrollMultiFactor = (uid) => {
    return multiFactor(auth.currentUser).unenroll(uid)
  }

  const getMfaResolver = (error) => {
    return getMultiFactorResolver(auth, error)
  }

  const startMfaSignIn = async (multiFactorHint, session) => {
    createRecaptchaVerifier()

    const phoneInfoOptions = {
      multiFactorHint,
      session
    }
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    // Send SMS verification code
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
  }

  const finishMfaSignIn = async (verificationId, multiFactorResolver, verificationCode) => {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    // Complete sign-in.
    await multiFactorResolver.resolveSignIn(multiFactorAssertion).then((updatedUser) => {
      setUser(updatedUser.user)
      setCounter((prev) => prev + 1)
      setIssueNewAccessToken(true)
    })
  }

  const getLoginProvider = () => {
    if (auth.currentUser && auth.currentUser.providerData?.length > 0) {
      return auth.currentUser?.providerData[0].providerId
    }
    return 'unknown'
  }

  useEffect(() => {
    // if the returned user is always null, see this stack overflow issue.
    // https://stackoverflow.com/questions/77270210/firebase-onauthstatechanged-user-returns-null-when-on-localhost
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser == null) {
        setIsAuthenticated(false)
        setIsLoading(false)
        setUser(null)
        clearCustomerStore()
      } else {
        setUser(currentUser)
        setIssueNewAccessToken(true)
        setIsAuthenticated(true)
        fetchPerson()
      }
      setCounter((prev) => prev + 1)
      setErrors([])
    })

    getRedirectResult(auth).catch((error) => {
      // catch specific MS login error
      if (error?.code) {
        if (error.code === 'auth/multi-factor-auth-required') {
          setErrors((previous) => [
            ...previous,
            {
              level: 'is-warning',
              message:
                'There was already a username password account used. Try login with username/password, or contact support.'
            }
          ])
        } else if (error.code === 'auth/user-mismatch') {
          setErrors((previous) => [
            ...previous,
            {
              level: 'is-warning',
              message:
                'There is a different user authenticated. Please consistently use the same username.'
            }
          ])
        } else {
          setErrors((previous) => [
            ...previous,
            {
              level: 'is-warning',
              message: error.code
            }
          ])
        }
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        createUser,
        sendVerificationEmail,
        handleActionCode,
        counter,
        user,
        errors,
        person,
        fetchPerson,
        resourceRoles,
        isAuthenticated,
        logout,
        signIn,
        reauthenticateWithPassword,
        passwordReset,
        confirmThePasswordReset,
        signInWithMicrosoft,
        reauthenticateWithMicrosoft,
        getAccessToken,
        isLoading,
        startEnrollMultiFactor,
        finishEnrollMultiFactor,
        unEnrollMultiFactor,
        getMfaResolver,
        startMfaSignIn,
        finishMfaSignIn,
        getLoginProvider
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const UserAuth = () => {
  return useContext(UserContext)
}
