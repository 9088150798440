import React from 'react'
import { UserAuth } from '../contexts/AuthContext'

export default function Footer() {
  const { isAuthenticated } = UserAuth()

  return (
    isAuthenticated && (
      <footer className="footer">
        <div className="content has-text-right">
          <p>
            Copyright ©{new Date().getFullYear()} <strong>Gordium®</strong> All Rights Reserved.
            Application version: {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </footer>
    )
  )
}
