import React from 'react'
import Icon from './Icon'

export default function ListItemChecked({ value }) {
  return (
    <li>
      <span className="icon-text">
        <Icon icon="ic:outline-check" extraOuterClasses="has-text-success" />
        <span>{value}</span>
      </span>
    </li>
  )
}
