import React from 'react'
import BackgroundBalls from '../../components/BackgroundBalls'
import SignInComponent from './SignInComponent'
import ListItemChecked from '../../components/ListItemChecked'

export default function SignInView() {
  return (
    <>
      <BackgroundBalls />
      <div>
        <div className="columns is-flex is-vcentered is-fullheight">
          <div className="column">&nbsp;</div>
          <div className="column is-8-widescreen is-10-desktop is-11-mobile is-8-tablet">
            <div className="columns is-vcentered">
              <div className="column is-5 is-hidden-touch">
                <div className="description-container">
                  <div className="login-ellipse">&nbsp;</div>
                  <p className="title is-4 has-text-primary-dark-blue">
                    Yes! We&apos;ve got a new look
                  </p>
                  <p className="subtitle is-5">
                    As of now we are called Gordium. Nice to see you here!
                  </p>
                  <img src="/name-change-gordium.png" alt="Gordium logo" className="mb-6" />
                </div>
                <p className="title is-4 mt-6">
                  Welcome back to the next-gen Process Hazard Analysis platform
                </p>
                <div className="content">
                  <ul
                    className="blockList mt-0"
                    style={{ listStyleType: 'none', margin: 0, padding: 0 }}
                  >
                    <ListItemChecked value="Contribute using integrated messaging" />
                    <ListItemChecked value="Fast track using scenario knowledge graphs" />
                    <ListItemChecked value="Regular check for new assessment Insights" />
                  </ul>
                </div>
              </div>
              <div className="column is-1 is-hidden-touch">&nbsp;</div>
              <div className="column is-6 is-12-touch">
                <div className="is-hidden-desktop mt-4">
                  <img src="/name-change-gordium.png" alt="Gordium logo" className="mb-6" />
                </div>
                <div className="card">
                  <div className="card-content p-5">
                    <div className="content">
                      <SignInComponent />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">&nbsp;</div>
        </div>
      </div>
    </>
  )
}
